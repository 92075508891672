// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//= require turbolinks
require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");
require("datatables.net-dt");
require("jquery-validation");
require("select2");
require("trix");
require("@rails/actiontext");
require("slick-carousel");
require("popper.js").default;
import "core-js/stable";
import "regenerator-runtime/runtime";
import "../stylesheets/application";
import "./bootstrap_custom.js";
import "./datatables.min.js";
import "@fortawesome/fontawesome-free/js/all";
import "eonasdan-bootstrap-datetimepicker";
import "bootstrap-select";
import Turbolinks from "turbolinks";

Turbolinks.start();
// import "select2";
import "bootstrap-select/dist/css/bootstrap-select.min.css";

import moment from "moment";
import Swal from "sweetalert2";
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context("../images", true);
const imagePath = (name) => images(name, true);

import "slick-carousel/slick/slick.scss";
document.addEventListener("turbolinks:load", function () {
  $(".next a").text("");
  $(".next a").append('<i class="fas fa-chevron-right"></i>');
  $(".prev a").text("");
  $(".prev a").append('<i class="fas fa-chevron-left"></i>');
  $(".dropdown-toggle").dropdown();
  if ($(".user-pass-top").length) {
    $("#showUserPasswordModal").modal("show");
  }

  if ($("#status_select").val() === "Hired") {
    $(".show_date").css("display", "block");
  } else {
    if ($(".show_date").length > 0) {
      $(".show_date").css("display", "none");
    }
  }

  $("#search-by-country").select2({
    closeOnSelect: false,
    placeholder: "Select Locations",
    allowClear: true,
  });
  var url = window.location.href;
  console.log("uer", url);
  if (url.indexOf("job_location") > -1) {
    var locationArr = $("#job_location").val().split(",");
    console.log("locationStr", locationArr);
    if (locationArr.length > 0) {
      console.log("called change");
      $("#search-by-country").val(locationArr).trigger("change");
      // $("#search-by-location").trigger("change");
      var uldiv = $("#search-by-country").siblings("span.select2").find("ul");
      var count = $("#search-by-country").val().length;
      if (count !== 0) {
        if (count === 1) {
          uldiv.html("<li>" + count + " item selected</li>");
        } else {
          uldiv.html("<li>" + count + " items selected</li>");
        }
      }
    }
  }
  $("#search-by-country").on("change", function (evt) {
    var uldiv = $(this).siblings("span.select2").find("ul");
    var count = $(this).select2("data").length;
    console.log("here called1");
    if (count !== 0) {
      if (count === 1) {
        uldiv.html("<li>" + count + " item selected</li>");
      } else {
        uldiv.html("<li>" + count + " items selected</li>");
      }
    }
    $("#job_location").val($(this).val());
  });
  $("#search-by-location").select2({
    closeOnSelect: false,
    placeholder: "Select Locations",
    allowClear: true,
  });
  $("#search-by-location").on("change", function (evt) {
    console.log("called from application js");
    var uldiv = $(this).siblings("span.select2").find("ul");
    var count = $(this).select2("data").length;
    if (count !== 0) {
      if (count === 1) {
        uldiv.html("<li>" + count + " item selected</li>");
      } else {
        uldiv.html("<li>" + count + " items selected</li>");
      }
    }
    // console.log("$(this).select2", $(this).select2("data"))
    $("#job_post_location").val($(this).val());
    $(".location-dropdown > .select2-container").removeClass(
      "validation-error"
    );
  });
  $("#status_select").change(function () {
    // alert($(this).val());
    if ($(this).val() === "Hire" || $(this).val() === "Hired") {
      $(".show_date").css("display", "block");
    } else {
      console.log("else part");
      if ($(".show_date").length > 0) {
        $("#hired_date").prop("required", false);
        $(".show_date").css("display", "none");
      }
    }
  });

  $(".test-pass").click(function () {
    if ($("#register_applicant_password").attr("type") == "password") {
      $(".field-icon-splash").css("display", "block");
      $(".field-icon").css("display", "none");
      $("#register_applicant_password").attr("type", "text");
    } else {
      $(".field-icon-splash").css("display", "none");
      $(".field-icon").css("display", "block");
      $("#register_applicant_password").attr("type", "password");
    }
  });

  $("#model-close").click(function () {
    $("#registrationModel").modal("hide");
  });

  $(".carrer-page-search").click(function (e) {
    e.preventDefault();
    $("#search-job-posts").submit();
    setTimeout(function () {
      // alert("Hello");
      $("html, body").animate({
        scrollTop: $("#search-by-depart").position().top,
      });
    }, 800);
  });

  // Close forgot Model
  $("#forgot-model-close").click(function () {
    $("#forgotModel").modal("hide");
  });
  // Show application clear model
  $(".application-clear-btn").click(function () {
    $("#clearModel").modal("show");
  });

  // Handle register error
  $("#register_new_applicant").submit(submitRegisterForm);
  $("#close-model").click(function () {
    $("#clearModel").modal("hide");
  });
  $("#forgot-model-close").click(function () {
    $("#forgotModel").modal("hide");
  });
  $("#forgot_new_applicant").bind("ajax:success", handleforgotSuccess);
  $("#forgot_new_applicant").bind("ajax:error", handleforgotSuccess);

  $(".before-submit").on("click", removeFormValidations);
  $(document).on("click", ".open-deleteOfficialModal", function () {
    $(".modal-body #id").val($(this).data("official_id"));
  });
  $(document).on("click", ".open-editOfficialModal", function () {
    var official_id = $(this).data("official_id");
    $(".modal-body #id").val(official_id);
    $.ajax({
      url: "/hr/officials/" + official_id + "",
      type: "GET",
      success: (data) => {
        $(".modal-body #official_email").val(data["email"]);
        $(".modal-body #official_first_name").val(data["first_name"]);
        $(".modal-body #official_last_name").val(data["last_name"]);
        $(".modal-body #official_role").val(data["role"]);
      },
    });
  });

  $(document).on("click", ".open-editReferenceModal", function () {
    var reference_id = $(this).data("reference_id");
    $("#reference_id").val(reference_id);
    var email = $(this).data("email");
    $("#reference_email").val(email);
  });

  $(document).on("ready", function () {
    var navListItems = $("div.setup-panel div a"),
      allWells = $(".setup-content"),
      allNextBtn = $(".nextBtn"),
      allPrevBtn = $(".prevBtn");

    allWells.hide();

    navListItems.click(function (e) {
      e.preventDefault();
      var $target = $($(this).attr("href")),
        $item = $(this);

      if (!$item.hasClass("disabled")) {
        navListItems.removeClass("btn-indigo").addClass("btn-default");
        $item.addClass("btn-indigo");
        allWells.hide();
        $target.show();
      }
    });

    allPrevBtn.click(function () {
      console.log("inside prev btn function");
      var curStep = $(this).closest(".setup-content"),
        curStepBtn = curStep.attr("id"),
        prevStepSteps = $('div.setup-panel div a[href="#' + curStepBtn + '"]')
          .parent()
          .parent()
          .prev()
          .children()
          .children("a");
      console.log("curStep", curStepBtn);

      prevStepSteps.removeAttr("disabled").trigger("click");
    });

    allNextBtn.click(function () {
      console.log("inside next btn function");
      var curStep = $(this).closest(".setup-content"),
        curStepBtn = curStep.attr("id"),
        nextStepWizard = $('div.setup-panel div a[href="#' + curStepBtn + '"]')
          .parent()
          .parent()
          .next()
          .children()
          .children("a");

      nextStepWizard.removeAttr("disabled").trigger("click");
    });

    $("div.setup-panel div a.btn-indigo").trigger("click");
  });

  $(".change-status").click(function (e) {
    e.preventDefault;
    $("#myhiddensection").css("display", "block");
    $(".change-status").css("display", "none");
  });

  $(".btn-applicant-status").on("click", function (e) {
    // alert("submit called");
    var formToSubmit = $("#applicant_status");
    formToSubmit.submit();
  });
  $(".watch-btn").on("click", function (e) {
    var url = $(this).attr("name");
    console.log("$(this).name", $(this).attr("name"));
    $("#jobVideo").attr("src", url);
    $("#showVideoModal").modal("show");
  });
  $(".video-btn").on("click", function (e) {
    var url = $(this).attr("name");
    console.log("$(this).name", $(this).attr("name"));
    $("#jobVideo").attr("src", url);
    $("#showVideoModal").modal("show");
  });
  $("#showVideoModal").on("hide.bs.modal", function () {
    // do something…
    $("#jobVideo").attr("src", "");
    console.log("modal close called");
  });
  // $("#upload-ref").click(triggerClick);
  $("#select-file-ref").on("change", handleFileSelect);
  // $("html").on("dragover", function(e) {
  //     e.preventDefault();
  //     e.stopPropagation();
  // });
  // // preventing page from redirecting
  // $("html").on("drop", function(e) {
  //     e.preventDefault();
  //     e.stopPropagation();

  //     console.log("111111");
  // });
  // // preventing page from redirecting
  // $(".upload-doc-container-ref").on("dragover", function(e) {
  //     e.stopPropagation();
  //     e.preventDefault();

  //     console.log("111111");
  // });
  // Drop event
  $(".upload-doc-container-ref").on("drop", handleFileDrop);
});

function handleFileDrop(e) {
  e.preventDefault();
  e.stopPropagation();
  console.log("111111");
  const files = e.originalEvent.dataTransfer.files;
  const alreadyAddedFiles = $("#select-file-ref").prop("file") || [];
  console.log("file", files[0]);
  console.log("alreadyAddedFiles", alreadyAddedFiles.length);
  var ext = files[0].name.split(".").pop();
  if (ext == "pdf") {
    if (alreadyAddedFiles.length > 0) {
      $(".upload-file-details-ref").empty();
      $("#select-file-ref").prop("file", "");
    }
    $("#select-file-ref").prop("file", files);
    $(".upload-file-details-ref")
      .append(`<div class="upload-item item-${files[0].name
      .split(" ")
      .join("__")}">
        <p class="upload-item-name">${files[0].name}</p>
        </div>`);
    $(".upload-file-details-ref").addClass("show");
  } else {
    Swal.fire({
      title: "Error",
      text: `Please upload pdf file.`,
      icon: "error",
      showCancelButton: false,
      confirmButtonText: "OK",
    });
  }
}

function handleFileSelect(e) {
  e.preventDefault();
  e.stopImmediatePropagation();
  const alreadyAddedFiles = $("#select-file-ref").prop("file") || [];
  const files = $(this).prop("files");
  const type = files[0].type;
  // console.log("file", files[0]);
  // console.log("alreadyAddedFiles", alreadyAddedFiles.length);
  var ext = files[0].name.split(".").pop();
  if (type === "application/pdf") {
    if (alreadyAddedFiles.length > 0) {
      $(".upload-file-details-ref").empty();
      $("#select-file-ref").prop("file", "");
    }
    $("#select-file-ref").prop("file", files);
    $(".upload-file-details-ref")
      .append(`<div class="upload-item item-${files[0].name
      .split(" ")
      .join("__")}">
       <p class="upload-item-name">${files[0].name}</p>
        </div>`);
    $(".upload-file-details-ref").addClass("show");
  } else {
    Swal.fire({
      title: "Error",
      text: `Please upload a pdf file.`,
      icon: "error",
      showCancelButton: false,
      confirmButtonText: "OK",
    });
    $("#select-file-ref").val("");
  }
}

function triggerClick(e) {
  e.preventDefault();
  e.stopImmediatePropagation();
  $("#select-file-ref").trigger("click");
}

function checkUrl(url) {
  alert("check url called", url);
  console.log("cakked ");
  var regExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
  console.log("regExp", url);
  var match = url.match(regExp);
  if (match && match[2].length == 11) {
    return true;
  } else {
    return false;
  }
}

function showErrorMsg(text) {
  Swal.fire({
    title: "Error",
    text,
    icon: "error",
    showCancelButton: false,
    confirmButtonText: "OK",
  });
}

function download_file() {
  alert("called ********");
  var link = document.createElement("a");
  link.href =
    "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf";
  link.download = "fileName";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  return false;
}

function removeFormValidations() {
  console.log("function called");
  if ($(".title-val").val()) {
    $(".description-val").prop("required", false);
    $("#loadingModal").modal("show");
    $("#job_post_form").submit();
  } else {
    console.log("$(.title-val", $(".title-val").val());
    $(".title-val").prop("required", true);
    var form = $("#job_post_form");
    console.log("form", form);
    // form.submit();
    $("#job_post_form")[0].submit();
    return false;
  }
}

function checkNumberKey(e) {
  var charCode = e.which ? e.which : e.keyCode;
  if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  } else {
    return true;
  }
}

function handleResetValidation(e) {
  e.preventDefault();
  const $inputs = $("#reset_new_applicant :input");
  const values = {};
  $inputs.each(function () {
    if (this.name.includes("applicant")) {
      values[this.name.split("applicant[")[1].split("]")[0]] = $(this).val();
    }
  });
  if (values.password !== values.password_confirmation) {
    $(".reset-error").addClass("show").append(`<p>Password mismatch</p>`);
    return false;
  }
}

function handleforgotSuccess() {
  console.log(event);
  if (event.detail[0].success) {
    $("#forgotModel").modal("show");
  } else {
    event.detail[0].errors.forEach((error) => {
      if (!!error) {
        $(".forgot-error").append(`<p>${error}</p>`);
      }
    });
    $(".forgot-error").addClass("show");
    $('input[type="email"]').addClass("is-invalid");
  }
  return false;
}

function submitRegisterForm(e) {
  e.preventDefault();

  const $inputs = $("#register_new_applicant :input");
  const values = {};
  var v = grecaptcha.getResponse();

  $inputs.each(function () {
    if (this.name.includes("applicant")) {
      values[this.name.split("applicant[")[1].split("]")[0]] = $(this).val();
    }
  });
  if (
    values.email &&
    values.first_name &&
    values.last_name &&
    values.password &&
    v.length > 0
  ) {
    $(".register-error").removeClass("show").empty();
    $.post("/applicants", { applicant: values }, function (data, status) {
      const { success } = data;
      if (success) {
        $("#registrationModel").modal("show");
      } else {
        data.errors.forEach((error) => {
          $(".register-error").append(`<p>${error}</p>`);
          if (error.includes("Email")) {
            $('input[type="email"]').addClass("is-invalid");
          }
          if (error.includes("Password")) {
            $('input[type="password"]').addClass("is-invalid");
          }
          if (error.includes("First name")) {
            $("#applicant_first_name").addClass("is-invalid");
          }
          if (error.includes("Last name")) {
            $("#applicant_last_name").addClass("is-invalid");
          }
        });
        $(".register-error").addClass("show");
      }
    });
  } else {
    $(".register-error").append(`<p>Please select captcha</p>`);
    $(".register-error").addClass("show");
  }
  return false;
}

function addNewPublication(e) {
  e.preventDefault();
  e.stopImmediatePropagation();
  $(".publication-list").append(`
    <div class="url-main">
      <input type="text" class="source" aria-describedby="source">
      <input type="url" class="url" aria-describedby="url">
      <button type="button" class="delete-publication">X</button>
    </div>
  `);
}

function removePublication(e) {
  e.preventDefault();
  e.stopImmediatePropagation();
  $(this).parent("div").remove();
}

function handleSelectnumber(e) {
  e.preventDefault();
  e.stopImmediatePropagation();
  console.log($(this)[0].innerText);
  $(".phone-btn").html($(this)[0].innerText);
  $(".phone-menu").removeClass("show");
  return false;
}

function clearform(e) {
  e.preventDefault();
  $("#job_application_form")[0].reset();
  $(".selected-skills").empty();
  $("#searchSkill").selectpicker("val", "");
  $("#clearModel").modal("hide");
}

function readFile(input) {
  if (input.files && input.files[0]) {
    var reader = new FileReader();

    reader.onload = function (e) {
      var htmlPreview = "<p>" + input.files[0].name + "</p>";
      var wrapperZone = $(input).parent();
      var previewZone = $(input).parent().parent().find(".preview-zone");
      var boxZone = $(input)
        .parent()
        .parent()
        .find(".preview-zone")
        .find(".box")
        .find(".box-body");

      wrapperZone.removeClass("dragover");
      previewZone.removeClass("hidden");
      boxZone.empty();
      boxZone.append(htmlPreview);
    };

    reader.readAsDataURL(input.files[0]);
  }
}

$(".dropzone").change(function () {
  readFile(this);
});

$(".dropzone-wrapper").on("dragover", function (e) {
  e.preventDefault();
  e.stopPropagation();
  $(this).addClass("dragover");
});

$(".dropzone-wrapper").on("dragleave", function (e) {
  e.preventDefault();
  e.stopPropagation();
  $(this).removeClass("dragover");
});

$(".remove-preview").on("click", function () {
  var boxZone = $(this).parents(".preview-zone").find(".box-body");
  var previewZone = $(this).parents(".preview-zone");
  var dropzone = $(this).parents(".form-group").find(".dropzone");
  boxZone.empty();
  previewZone.addClass("hidden");
  reset(dropzone);
});
